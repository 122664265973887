import { Slot } from "@radix-ui/react-slot"
import { forwardRef, type ComponentPropsWithoutRef } from "react"
import { tv, type VariantProps } from "../tv"

const button = tv({
  base: "inline-flex items-center justify-center text-center transition disabled:pointer-events-none",
  variants: {
    variant: {
      primary: "",
      secondary: "",
      tertiary: "",
    },
    colorScheme: {
      default: "",
      accent: "",
      danger: "",
      light: "",
      main: "",
      black: "",
      orange: "",
      lightAccent: "",
    },
    size: {
      sm: "duration-100",
      md: "duration-150",
      lg: "duration-200",
    },
  },
  compoundVariants: [
    {
      colorScheme: ["default", "accent", "danger", "light", "lightAccent"],
      size: "sm",
      className: "h-6 rounded px-2 text-strong font-semibold",
    },
    {
      colorScheme: ["default", "accent", "danger", "light", "lightAccent"],
      size: "md",
      className: "h-8 rounded-lg px-3 text-[14px] font-semibold",
    },
    {
      colorScheme: "default",
      variant: "primary",
      className:
        "border border-transparent bg-transparent-light text-gray-100 hover:bg-transparent-medium focus-visible:border-blue focus-visible:outline-none focus-visible:ring active:bg-transparent-strong disabled:text-text-extraLight",
    },
    {
      colorScheme: "default",
      variant: "secondary",
      className:
        "border border-transparent-medium text-text-secondary hover:bg-transparent-light focus-visible:border-blue focus-visible:outline-none focus-visible:ring active:bg-transparent-light disabled:text-text-extraLight",
    },
    {
      colorScheme: "default",
      variant: "tertiary",
      className:
        "border border-transparent text-text-secondary hover:bg-transparent-light focus-visible:border-blue focus-visible:outline-none disabled:text-text-extraLight",
    },

    {
      colorScheme: "accent",
      variant: "primary",
      className:
        "border border-transparent-light bg-blue text-text-inverted hover:bg-blue-50 focus-visible:outline-none focus-visible:ring active:bg-blue-60 disabled:opacity-[24%]",
    },
    {
      colorScheme: "accent",
      variant: "secondary",
      className:
        "border border-accent-35 text-blue hover:bg-accent-15 focus-visible:border-blue focus-visible:outline-none focus-visible:ring active:bg-accent-20 disabled:border-accent-20 disabled:text-accent-40",
    },
    {
      colorScheme: "accent",
      variant: "tertiary",
      className:
        "border border-transparent text-blue hover:bg-accent-10 focus-visible:border-blue focus-visible:outline-none focus-visible:ring active:bg-accent-15 disabled:text-accent-40",
    },

    {
      colorScheme: "danger",
      variant: "primary",
      className:
        "border border-transparent-light bg-red text-text-inverted hover:bg-red-50 focus-visible:outline-none focus-visible:ring focus-visible:ring-danger/10 disabled:opacity-[24%]",
    },
    {
      colorScheme: "danger",
      variant: "secondary",
      className:
        "border border-borders-danger text-red hover:bg-bg-danger focus-visible:border-red focus-visible:outline-none focus-visible:ring focus-visible:ring-danger/10 disabled:opacity-20",
    },
    {
      colorScheme: "danger",
      variant: "tertiary",
      className:
        "border border-transparent text-red hover:bg-bg-danger focus-visible:border-red focus-visible:outline-none focus-visible:ring focus-visible:ring-danger/10 disabled:opacity-20",
    },

    {
      colorScheme: "main",
      className: "h-8 w-[200px] rounded-lg text-stronger disabled:shadow-none",
    },
    {
      colorScheme: "main",
      variant: "primary",
      className:
        "border border-transparent-light bg-[radial-gradient(50%_62.62%_at_50%_0%,#505050_0%,#333_100%)] text-text-inverted shadow-light hover:bg-[radial-gradient(76.32%_95.59%_at_50%_0%,#505050_0%,#333_100%)] disabled:bg-bg-secondary disabled:text-text-extraLight",
    },
    {
      colorScheme: "main",
      variant: "secondary",
      className:
        "border border-borders-medium bg-bg-primary text-text-primary shadow-light hover:bg-bg-tertiary disabled:border-transparent-light disabled:bg-bg-secondary disabled:text-text-extraLight",
    },

    {
      colorScheme: "light",
      variant: ["secondary", "tertiary"],
      className:
        "border border-transparent font-normal hover:bg-transparent-light focus-visible:border-blue focus-visible:text-blue focus-visible:outline-none focus-visible:ring active:bg-transparent-medium disabled:text-text-extraLight",
    },
    {
      colorScheme: "light",
      variant: "secondary",
      className: "text-text-secondary",
    },
    {
      colorScheme: "light",
      variant: "tertiary",
      className: "text-text-tertiary",
    },
    {
      colorScheme: "light",
      variant: "secondary",
      className: "text-text-secondary",
    },
    {
      colorScheme: "light",
      variant: "tertiary",
      className: "text-text-tertiary",
    },

    {
      colorScheme: "lightAccent",
      variant: ["secondary", "tertiary"],
      className:
        "border border-transparent font-normal text-blue hover:bg-accent-quarternary focus-visible:border-blue focus-visible:outline-none focus-visible:ring active:bg-accent-tertiary disabled:text-text-extraLight",
    },

    {
      colorScheme: "black",
      variant: "primary",
      className:
        "border border-transparent-light bg-gray-100 text-text-inverted hover:bg-gray-55 focus-visible:border-blue focus-visible:outline-none focus-visible:ring disabled:text-text-light",
    },
    {
      colorScheme: "black",
      size: "sm",
      className: "h-6 rounded-md px-1.5 text-stronger",
    },
    {
      colorScheme: "black",
      size: "md",
      className: "h-8 rounded-lg px-3 text-[14px] text-stronger",
    },

    {
      colorScheme: "orange",
      variant: "primary",
      className:
        "bg-orange text-text-inverted hover:bg-gray-100 disabled:text-transparent-secondary",
    },
    {
      colorScheme: "orange",
      variant: "primary",
      size: "sm",
      className:
        "h-6 rounded-md px-1.5 text-stronger hover:bg-orange hover:opacity-80",
    },
    {
      colorScheme: "orange",
      variant: "primary",
      size: "md",
      className: "h-8 rounded-lg px-3 text-[14px] font-semibold",
    },
    {
      colorScheme: "orange",
      variant: "primary",
      size: "lg",
      className: "h-12 rounded-xl px-6 text-h3",
    },
    {
      colorScheme: "orange",
      variant: "secondary",
      className:
        "h-12 rounded-[24px] bg-bg-primary px-6 text-[16px] font-semibold text-orange hover:text-gray-100 disabled:text-orange-40",
    },
  ],
  defaultVariants: {
    variant: "primary",
    colorScheme: "default",
    size: "sm",
  },
})

interface ButtonProps
  extends ComponentPropsWithoutRef<"button">,
    VariantProps<typeof button> {
  asChild?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asChild, variant, colorScheme, size, className, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={button({ variant, colorScheme, size, className })}
        ref={ref}
        {...props}
      />
    )
  },
)

Button.displayName = "Button"
